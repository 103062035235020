import { StaticImage } from 'gatsby-plugin-image';

import CMCLogo from 'images/brokerAnimation/cmc.inline.svg';
import CharlesSchwabLogo from 'images/brokerAnimation/charles-schwab.inline.svg';
import RBCLogo from 'images/brokerAnimation/rbc.inline.svg';
import Trading212Logo from 'images/brokerAnimation/trading-212.inline.svg';

import type { ReactNode } from 'react';

interface Logo {
  key: string;
  logo: ReactNode;
}

interface Logos {
  [key: string]: Logo;
}

interface AllLogos {
  [key: string]: Logo[];
}

export const allLogos: Logos = {
  'interactive-brokers': {
    key: 'interactive-brokers-logo',
    logo: (
      <StaticImage
        src="../../images/brokerAnimation/interactive-brokers.png"
        alt="Interactive Brokers logo"
        width={120}
      />
    ),
  },
  'trading-212': {
    key: 'trading-212-logo',
    logo: <Trading212Logo />,
  },
  'charles-schwab': {
    key: 'charles-schwab-logo',
    logo: <CharlesSchwabLogo />,
  },
  robinhood: {
    key: 'robinhood-logo',
    logo: <StaticImage src="../../images/brokerAnimation/robinhood.png" alt="Robinhood logo" />,
  },
  cmc: {
    key: 'cmc-logo',
    logo: <CMCLogo />,
  },
  'hargreaves-lansdown': {
    key: 'hargreaves-lansdown-logo',
    logo: (
      <StaticImage
        src="../../images/brokerAnimation/hargreaves-lansdown.png"
        alt="Hargreaves Lansdown logo"
      />
    ),
  },
  pearler: {
    key: 'pearler-logo',
    logo: <StaticImage src="../../images/brokerAnimation/pearler.png" alt="Pearler logo" />,
  },
  commsec: {
    key: 'commsec-logo',
    logo: <StaticImage src="../../images/brokerAnimation/commsec.png" alt="Commsec logo" />,
  },
  selfwealth: {
    key: 'selfwealth',
    logo: <StaticImage src="../../images/brokerAnimation/selfwealth.png" alt="Selfwealth logo" />,
  },
  sharesies: {
    key: 'sharesies-logo',
    logo: <StaticImage src="../../images/brokerAnimation/sharesies.png" alt="Sharesies logo" />,
  },
  hatch: {
    key: 'hatch-logo',
    logo: <StaticImage src="../../images/brokerAnimation/hatch.png" alt="Hatch logo" />,
  },
  'jarden-direct': {
    key: 'jarden-direct-logo',
    logo: (
      <StaticImage src="../../images/brokerAnimation/jarden-direct.png" alt="Jarden Direct logo" />
    ),
  },
  'asb-securities': {
    key: 'asb-securities-logo',
    logo: <StaticImage src="../../images/brokerAnimation/asb.png" alt="ASB Securities logo" />,
  },
  questrade: {
    key: 'questrade-logo',
    logo: <StaticImage src="../../images/brokerAnimation/questrade.png" alt="Questrade logo" />,
  },
  wealthsimple: {
    key: 'wealthsimple-logo',
    logo: (
      <StaticImage src="../../images/brokerAnimation/wealthsimple.png" alt="Wealthsimple logo" />
    ),
  },
  'cibc-investors-edge': {
    key: 'cibc-investors-edge-logo',
    logo: (
      <StaticImage src="../../images/brokerAnimation/cibc.png" alt="Cibc Investors Edge logo" />
    ),
  },
  'rbc-direct': {
    key: 'rbc-direct-logo',
    logo: <RBCLogo />,
  },
  'interactive-investor': {
    key: 'interactive-investor-logo',
    logo: (
      <StaticImage
        src="../../images/brokerAnimation/interactive-investor.png"
        alt="Interactive Investor logo"
      />
    ),
  },
};

export const logos: AllLogos = {
  global: [
    allLogos['interactive-brokers'],
    allLogos['trading-212'],
    allLogos['charles-schwab'],
    allLogos.robinhood,
    allLogos.cmc,
    allLogos['hargreaves-lansdown'],
  ],
  au: [
    allLogos.cmc,
    allLogos.pearler,
    allLogos.commsec,
    allLogos.selfwealth,
    allLogos['interactive-brokers'],
    allLogos['trading-212'],
  ],
  nz: [
    allLogos.sharesies,
    allLogos.hatch,
    allLogos['jarden-direct'],
    allLogos['asb-securities'],
    allLogos['interactive-brokers'],
    allLogos['trading-212'],
  ],
  ca: [
    allLogos.questrade,
    allLogos.wealthsimple,
    allLogos['interactive-brokers'],
    allLogos['cibc-investors-edge'],
    allLogos['rbc-direct'],
    allLogos['trading-212'],
  ],
  uk: [
    allLogos['trading-212'],
    allLogos['interactive-brokers'],
    allLogos['hargreaves-lansdown'],
    allLogos['interactive-investor'],
    allLogos.cmc,
    allLogos['charles-schwab'],
  ],
};
