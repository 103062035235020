import styled from 'styled-components';
import type { BrokerAnimationProps } from './BrokerAnimation';

export const Gradient = styled.div<BrokerAnimationProps>`
  background: ${({ theme, background }) =>
    background ? theme.colors[background] : theme.colors.white};
  background: ${({ theme, background }) =>
    `linear-gradient(90deg, ${
      background ? theme.colors[background] : theme.colors.white
    } 60%, rgba(255, 255, 255, 0) 100%)`};
  width: 150px;
  height: 400px;
  position: absolute;
  z-index: 1;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 115px;
    height: 200px;
  }
`;
