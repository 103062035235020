import { useLocalizationContext } from '@sharesight/gatsby-plugin-sharesight-localization';
import { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { useMediaQuery } from 'styled-breakpoints/use-media-query';
import { useGatsbyImage } from 'hooks/useGatsbyImage';
import { FlexContainer } from 'components/FlexContainer';

import { Box } from 'components/Box';
import { Gradient } from './Gradient.styled';
import { LogosContainer } from './LogosContainer.styled';
import { LogoWrapper } from './LogoWrapper.styled';
import { logos } from './brokerLogos';

import type { FC } from 'react';
import type { Color } from 'styled/types';

export interface BrokerAnimationProps {
  background?: Extract<Color, 'white' | 'cream'>;
}

export const BrokerAnimation: FC<BrokerAnimationProps> = ({ background }) => {
  const { currentLocale } = useLocalizationContext();

  const brokerLogos = logos[currentLocale.id] ? logos[currentLocale.id] : logos.global;
  const { breakpoints } = useTheme();

  const isMobile = useMediaQuery(breakpoints.down('md'));

  const mappedLogos = useMemo(
    () =>
      brokerLogos.map(brokerLogo => (
        <LogoWrapper key={brokerLogo.key}>
          <Box maxWidth={isMobile ? '70px' : '90px'}>{brokerLogo.logo}</Box>
        </LogoWrapper>
      )),
    [brokerLogos, isMobile]
  );

  return (
    <FlexContainer>
      <Gradient background={background} />
      <LogosContainer>{mappedLogos}</LogosContainer>
      <Box maxWidth={isMobile ? '120px' : '200px'}>
        {useGatsbyImage({ name: 'brokerAnimation/report-mobile', alt: 'phone' })}
      </Box>
    </FlexContainer>
  );
};
