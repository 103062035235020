import styled, { keyframes } from 'styled-components';
import { getDimension } from 'styled/helpers/getDimension';

const movingAnimation = keyframes`
    from {
        transform: translateX(-20%);
    }

    to {
        transform: translateX(110%);
    }
 `;

const firstRowTop = '20%';
const secondRowTop = '45%';
const thirdRowTop = '70%';

export const LogoWrapper = styled.div`
  position: absolute;
  animation: ${movingAnimation} 6s infinite ease-out;
  width: 100%;

  &:nth-of-type(1) {
    top: ${firstRowTop};
  }

  &:nth-of-type(2) {
    top: ${firstRowTop};
    animation-delay: 3s;
  }

  &:nth-of-type(3) {
    top: ${secondRowTop};
    animation-delay: 2s;
  }

  &:nth-of-type(4) {
    top: ${secondRowTop};
    animation-delay: 5s;
  }

  &:nth-of-type(5) {
    top: ${thirdRowTop};
    animation-delay: 1s;
  }

  &:nth-of-type(6) {
    top: ${thirdRowTop};
    animation-delay: 4s;
  }

  img {
    max-height: ${getDimension('size9')};
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    img {
      max-height: ${getDimension('size6')};
    }
  }
`;
